import { Component, OnInit,ViewChild,Renderer2,Input } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'canvas-draw',
  templateUrl: './canvas-draw.component.html',
  styleUrls: ['./canvas-draw.component.css']
})
export class CanvasDraw {

  @ViewChild('myCanvas') canvas: any;
  @Input() invoice: string;

  canvasElement: any;
  lastX: number;
  lastY: number;

  currentColour: string = '#1A42BC';
  brushSize: number = 4;

  constructor(public platform: Platform, public renderer: Renderer2) {
      console.log('Hello CanvasDraw Component');
  }

  ngAfterViewInit(){

      this.canvasElement = this.canvas.nativeElement;

      this.renderer.setAttribute(this.canvasElement, 'width', this.platform.width() + '');
      this.renderer.setAttribute(this.canvasElement, 'height', this.platform.height()/2 + '');

  }

  handleStart(ev){

      this.lastX = ev.touches[0].pageX;
      this.lastY = ev.touches[0].pageY;
  }

  handleMove(ev){

      let ctx = this.canvasElement.getContext('2d');
      let currentX = ev.touches[0].pageX;
      let currentY = ev.touches[0].pageY;

      ctx.beginPath();
      ctx.lineJoin = "round";
      ctx.moveTo(this.lastX, this.lastY);
      ctx.lineTo(currentX, currentY);
      ctx.closePath();
      ctx.strokeStyle = this.currentColour;
      ctx.lineWidth = this.brushSize;
      ctx.stroke();

      this.lastX = currentX;
      this.lastY = currentY;

  }

  clearCanvas(){
    let ctx = this.canvasElement.getContext('2d');
    ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
}

send(){
  var image = this.canvasElement.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
  window.location.href=image;
}


}