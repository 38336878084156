import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackbuttonComponent } from './backbutton/backbutton.component';
import { LoadingComponent } from './loading/loading.component';
 
import { RouterModule } from '@angular/router';
 


@NgModule({
   declarations: [
    BackbuttonComponent,
    LoadingComponent, 
  ],
  exports: [
    BackbuttonComponent,
    LoadingComponent, 
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ]
})
export class ComponentsModule { }
