// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 999999;
}

ion-spinner {
  width: 80px;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;AAEJ","sourcesContent":["div{\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    margin: 0;\n    padding: 0;\n    position: absolute;\n    text-align: center;\n    width: 100%;\n    z-index: 999999;\n}\nion-spinner {\n    width: 80px;\n    height: 80px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
