import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Api } from './services/api';
import { UserData } from './services/userData';
// ReactiveForms
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CanvasDraw } from './canvas-draw/canvas-draw.component';
// NgCharts
import { ComponentsModule } from './components/components.module';
import {NgxIonicImageViewerModule} from '@herdwatch-apps/ngx-ionic-image-viewer';
import { NgCalendarModule  } from 'ionic2-calendar';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [AppComponent, CanvasDraw],
  imports: [HttpClientModule,BrowserModule,
    NgxIonicImageViewerModule,
    NgCalendarModule,
    NgSelectModule,
    FormsModule,
      IonicModule.forRoot({
      mode: 'ios',
       swipeBackEnabled: true,
   }),
    , ReactiveFormsModule, AppRoutingModule,ComponentsModule],
  providers: [FileOpener, File,Api,UserData,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
