import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface ApiImage {
  _id: string;
  name: string;
  url: any;
}


/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api {
 
    server: string = "https://playsikka.com/api/a1";
    imgurl: string = "https://playsikka.com/files";
    url: string = this.server;
 

  constructor(public http: HttpClient) {}

 
  loginGet(endpoint: string, reqOpts?: any) {

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    return this.http.get(this.url + "/" + endpoint, {
      headers: headers
    });
  }


  get(endpoint: string, reqOpts?: any) {

    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      sikka: "Bearer " + localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    return this.http.get(this.url + "/" + endpoint, {
      headers: headers
    });
  }

  getPDF(endpoint: string, reqOpts?: any) {
    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      sikka: "Bearer " + localStorage.getItem("token"),
      role: localStorage.getItem("role"),

      "Content-Type": "application/pdf",
      "Access-Control-Allow-Origin": "*"
    };
    return this.http.get<Blob>(this.url + "/" + endpoint, {
      headers: headers,responseType: 'blob' as 'json'
    });
  }


  post(endpoint: string, body: any, reqOpts?: any) {
 
    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      sikka: "Bearer " + localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    return this.http.post(this.url + "/" + endpoint, body, {
      headers: headers
    });
  }

  uploadFile(file) {

    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      inv: "Bearer " + localStorage.getItem("token"),
      // "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*"
    };

    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post(`${this.url}/UploadFile`, formData, {
      headers: headers
    });
  }

  uploadImage(blobData, name, ext,ID=null,type=null) {

    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      inv: "Bearer " + localStorage.getItem("token"),
      // "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*"
    };

    const formData = new FormData();
    formData.append('file', blobData, `myimage.${ext}`);
    formData.append('name', name);
    formData.append('ID', ID);
    formData.append('type', type);

    return this.http.post(`${this.url}/UploadImage`, formData, {
      headers: headers
    });
  }


  put(endpoint: string, body: any, reqOpts?: any) {
    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      sikka: "Bearer " + localStorage.getItem("token"),
      
      
    };
    return this.http.put(this.url + "/" + endpoint, body, {
      headers: headers
    });
  }

  delete(endpoint: string, type=null) {
    let headers = {
      userID: localStorage.getItem("userid"),
      Authorization: "Bearer " + localStorage.getItem("token"),
      sikka: "Bearer " + localStorage.getItem("token"),
      type: type,

      
    };
    return this.http.delete(this.url + "/" + endpoint, {
      headers: headers
    });
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + "/" + endpoint, body, reqOpts);
  }
}
