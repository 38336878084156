import { Injectable } from '@angular/core';

// After
import {Events} from '../services/Events';

@Injectable()
export class UserData {
    HAS_LOGGED_IN = 'hasLoggedIn';
    public LOGGED_IN: boolean;
    public bgImage = '';
    public circle = '';
    constructor(public events: Events) {
        this.LOGGED_IN = false;
    }
 
    login(user) {
        console.log("inside login function in service")
        this.LOGGED_IN = true;
        localStorage.setItem('hasLoggedIn','true');
        this.setUser(user);
        this.events.publish('user:login', {});
         this.events.publish('user:data', user);
    };

    logout() {
        console.log("inside logout function in service")
        this.LOGGED_IN = false;
        localStorage.removeItem('hasLoggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('type');
        this.events.publish('user:logout',{});
    };

    setUser(user) {
        localStorage.setItem('user',user);
    };

    setActiveCode(code) {
        console.log(code);
    };

    getActiveCode() {
        return '1';
         
    };

    getUser() {
        return localStorage.getItem('user');
    };

    getUserToken() {
        return localStorage.getItem('token');
    };

    // return a promise
    hasLoggedIn() {
        return localStorage.getItem('hasLoggedIn');

    };
}
