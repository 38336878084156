// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas-draw {
    height: 100%;
    width: 100%;
    display: block;
  
    #top-toolbar {
      position: absolute;
      top: 0;
    }
  
    #bottom-toolbar {
      position: absolute;
      bottom: 0;
    }
  }
  canvas{
    background-color: #e3e3e3;
  }`, "",{"version":3,"sources":["webpack://./src/app/canvas-draw/canvas-draw.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,cAAc;;IAEd;MACE,kBAAkB;MAClB,MAAM;IACR;;IAEA;MACE,kBAAkB;MAClB,SAAS;IACX;EACF;EACA;IACE,yBAAyB;EAC3B","sourcesContent":["canvas-draw {\n    height: 100%;\n    width: 100%;\n    display: block;\n  \n    #top-toolbar {\n      position: absolute;\n      top: 0;\n    }\n  \n    #bottom-toolbar {\n      position: absolute;\n      bottom: 0;\n    }\n  }\n  canvas{\n    background-color: #e3e3e3;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
